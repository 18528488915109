$(document).ready(function () {
	// Quantum Scripts
	let isQuantumEnabled = $('#quantumConfigs').data('is-quantum-enabled');
	let quantumSrc = $('#quantumConfigs').data('quantum-src');
	let isAdminNode = $('#isAdminNode').data('value');
	if (isTrue(isQuantumEnabled) && isFalse(isAdminNode)) {
		(function() {
			var qtm = document.createElement('script'); qtm.type = 'text/javascript'; qtm.async = 1;
			qtm.src = quantumSrc;
			var d = document.getElementsByTagName('script')[0];
			!window.QuantumMetricAPI && d.parentNode.insertBefore(qtm, d);
		})();
	}
});

$(window).on('load', function(){
	//TikTok Script
	let ttScriptFlag = $('#ttScriptConfig').attr('data-is-enabled');
	let ttScriptTimeout = $('#ttScriptConfig').attr('data-timeout');
	if  (isTrue(ttScriptFlag)) {
		setTimeout(() => {
			!function (w, d, t) {
				w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
				ttq.load('CIS47BRC77U0A5OSPHQG');
				ttq.page();
			}(window, document, 'ttq');
		}, ttScriptTimeout);
	}

	//Facebook Script
	let currentPgUrl = window.location.href;
	let fbScriptFlag = $('#fbScriptConfig').attr('data-is-enabled');
	let fbScriptTimeout = $('#fbScriptConfig').attr('data-timeout');
	if (isTrue(fbScriptFlag) && !currentPgUrl.includes('disable-third-parties=true')) {
		setTimeout(() => {
			!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
			n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
			document,'script','//connect.facebook.net/en_US/fbevents.js');
			fbq('init', '351141539063359');
		}, fbScriptTimeout);
	}
});